// sign up
export const SOMETHING_WRONG = 'Something went wrong. Please try again later';
export const INVALID_FIRST_NAME = 'Invalid first name';
export const INVALID_LAST_NAME = 'Invalid last name';
export const MAX_LENGTH_50 = 'The max length should be 50';
export const INVALID_FORMAT = 'Invalid format';
export const INVALID_EMAIL_ADDRESS = 'Invalid email address';
export const INVALID_MOBILE_NUMBER = 'Invalid mobile number';
export const INVALID_STREET_ADDRESS = 'Invalid street address';
export const INVALID_CITY = 'Invalid city';
export const REQUIRED_CITY = 'City is required';
export const REQUIRED_ADDRESS = 'Address is required';
export const INVALID_ZIP_CODE = 'Invalid zip code';
export const INVALID_DOB = 'Invalid date of birth';
export const REQUIRED_EMAIL = 'Email is required';
export const REQUIRED_LAST_NAME = 'Last name is required';
export const REQUIRED_FIRST_NAME = 'First name is required';
export const REQUIRED_ZIP_CODE = 'ZIP Code is required';
export const REQUIRED_SSN = 'SSN is required';
export const REQUIRED_MOBILENUMBER = 'Mobile number is required';
export const INVALID_MOBILENUMBER = 'Invalid phone number';
export const REQUIRED_BIRTHDAY = 'Date of Birth is required';
export const REQUIRED_AMOUNT = 'Amount is required';
export const REQUIRED_PASSWORD = 'Password is required';
export const MUST_18YEARS_OLD =
  'You must be 18 years of age or older to create an account';
export const PWD_MINIMUM_8_CHARS = '8 characters minimum';
export const PWD_ONE_SPECIAL_CHAR = 'One special character (!@#$%^&*)';
export const PWD_ONE_NUMBER = 'At least one number';
export const PWD_UPPER_LOWER_LETTERS = 'Use upper and lower case letters';
export const PWD_WHITESPACE = 'No invalid characters or spaces';
export const PWD_SAME = 'New password cannot be same as previous password';
export const ACCOUNT_EXISTS = 'An account with the given email already exists';
export const TERMS_SERVICE =
  'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla vitae elit libero, a pharetra augue. Sed posuere consectetur est at lobortis. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nullam quis risus eget urna mollis ornare vel eu leo.';
export const FILE_SIZE_ERROR =
  'This file exceeds 8mb, please upload a smaller file';
export const SSN_MEAN =
  'The last 4 digits of your SSN can help BuoyFi identify any medical bills from BuoyFi partners.';

// verification
export const CODE_MATCH_ERROR = 'This code doesn’t match the one sent.';
export const ALREADY_CONFRIMED = 'User is already confirmed.';
export const CODE_RESENT_MESSAGE =
  'We’ve sent you a new code in a text message.';
export const CODE_RESENT_EMAIL = 'We’ve sent you a new code in an email.';

export const CODE_RESENT_MESSAGE_ERROR =
  'We couldn`t send the code. Please try again.';

export const CODE_RESENT_TIME_OUT_ERROR =
  'It may take 20 seconds to receive your code. Please press ‘Resend code’ again if you still haven`t received your code after 20 seconds.';

export const PHONE_VERIFY_FAILED = 'Your phone number is not verified.';
export const PHONE_VERIFY_SUCCESS =
  'Your phone number was successfully verified.';
export const EMAIL_VERIFY_SUCCESS = 'Your email was successfully verified.';
export const EMAIL_VERIFY_FAILED = 'Your email is not verified.';

export const ENTER_VALID_EMAIL =
  'Please enter the email address associated with your account.';

export const BALANCE_ERROR = 'Insufficient available balance';
export const BALANCE_OVER_AMOUNT_ERROR =
  'Your account has insufficient funds for this transaction';

// sign in
export const SIGN_IN_ERROR =
  'This sign in information you entered doesn’t match what we have on file.';
export const INCORRECT_USERNAME_PASS = 'Incorrect username or password';
export const INCORRECT_INFO = 'Incorrect information';
export const UNABLE_TO_SIGNIN = 'Unable to sign in';
export const ACCOUNT_LOCKED =
  'Your account is locked due to 3 unsuccesful attempts. Please try again in 24 hours or contact support.';
export const MISMATCH_PHONE_NUMBER =
  'The phone number entered doesn’t match what we have on file';

export const PASSWORD_CHANGE_SUCCESS =
  'Your password has been changed successfully.';
export const PASSWORD_CHANGE_FAIL =
  'We were unable to reset password due to an unknown error. Please try again';
export const PASSWORD_NOT_MATCH = 'Passwords don’t match';
export const NETWORK_ERROR =
  'We are having trouble connecting to the internet, please check your network connection and try again.';

export const REMEMBER_ME_TOOLTIP = "By checking \'Remember me\', you consent to our Terms of Service."

// payment
export const ENTER_VALID_DATE = 'Please enter valid date';
export const TRANSACTION_NOT_PAST =
  'Transactions cannot be scheduled in the past';
export const SAVE_UP_TOOLTIP =
  'The balance of your BuoyFi Savings. Use this money to pay off medical bills, pay your bills, or withdraw back to your External Bank Account.';
export const ROUND_UP_TOOLTIP =
  'The amount of spare change ready to transfer to BuoyFi Savings.';
export const INVALID_AMOUNT = 'Please enter a valid dollar amount';
export const SP_NOT_AVAILABLE_TITLE = 'We are working on upgrading BuoyFi Savings and BuoyFi Payments to offer some exciting new features!';
export const SP_NOT_AVAILABLE_BODY = 'We will send a note to our members when the new features are live.';

// API
export const API_USER = 'userManagement';
export const API_SYNAPSE = 'synapseIntegration';
export const API_ROUNDUP = 'roundUp';
export const API_PLAID = 'plaidIntegration';
export const API_UTILS = 'common';
export const API_DIVINITY = 'divinityIntegration';
export const API_TRANSACTION = 'transaction';
export const API_CONTACT = 'contactManagement';
export const API_BILL_GO = 'billGoIntegration';
export const API_PDF = 'pdfExport';
export const API_NOTIFICATION = 'notificationInfo';

// Choose tool
export const CHOOSE_TOOL_TITLE = 'BuoyFi makes planning and saving easy!';
export const CHOOSE_TOOL_DESCRIPTION = `Let's choose a tool to start your BuoyFi experience. Don't worry, you can always set up the other one later.`;
export const CALCULATE_DESCRIPTION =
  'Use BuoyFi to build a payment solution you can afford';
export const CALCULATE_TOOL_DESCRIPTION =
  'Note: BuoyFi is not a debt collector.  BuoyFi Calculator is for information purposes and is not a settlement offer.';

// Compass and BillPay
export const SETUP_COMPASS_AGREEMENTP1 =
  'BuoyFi Calculator can calculate an affordable payment solution based on your medical bill balance and household income.';
export const SETUP_COMPASS_AGREEMENTP2 = `You’ll go through the following steps to determine what's affordable and whether you may qualify for bill reduction or forgiveness.`;
export const SETUP_COMPASS_AUTH =
  'I authorize BuoyFi to share my personal / financial information with BuoyFi partners.';

export const DEBT_INFO_TITLE = 'We have retrieved your bill information';
export const DEBT_INFO_MAIN_DESCRIPTION =
  'Based on our records, you have two bill accounts:';
export const DEBT_INFO_DESCRIPTIONP1 =
  'We were able to pull this information from our BuoyFi Partner based on your agreement to our ';
export const DEBT_INFO_DESCRIPTIONP2 =
  'Total Medical Bill does not include prescriptions, insurance premiums, dental care, or cosmetic care.';

export const ADD_MEDICAL_DEBT_TITLE = 'Add other medical bills (optional)';
export const ADD_MEDICAL_DEBT_DESCRIPTION =
  'Please provide details about any additional medical bills.';
export const ADD_MEDICAL_DEBT_DESCRIPTIONP1 =
  'How would you like to provide details on your additional medical bills?';

export const INVALID_NAME = 'Invalid name';
export const REQUIRED_NAME = 'Name is required';

export const CONFIRM_DEBT_INFO_TITLE = 'Review your bill information';
export const EDIT_DEBT_INFO_TITLE = 'Edit your bill info';
export const CONFIRM_DEBT_INFO_MAIN_DESCRIPTION =
  'Here is the breakdown and total of the medical bills you entered:';
export const CONFIRM_DEBT_INFO_DESCRIPTIONP1 =
  'Please make sure that the information is correct. You may make changes to the other medical bill section.';
export const PROVIDE_MEDICAL_DEBT_TITLE = 'Share your medical bill information';
export const PROVIDE_MEDICAL_DEBT_DESCRIPTION =
  'How would you like to provide your medical bill details?';

export const DEBT_HOLDER_MAX_ERROR =
  'You have reached the maximum number of accounts.';

export const CONNECT_PAYROLL_TITLE = 'Your income is verified!';
export const CONNECT_PAYROLL_MAIN_DESCRIPTION =
  'Your payment guideline will be based on income retrieved from this account:';
export const CONNECT_PAYROLL_SUCCESS = 'Your income has been verified';

export const PROVIDE_SPOUSE_INCOME_TITLE = 'Provide your spouse’s income';
export const PROVIDE_SPOUSE_INCOME_MAIN_DESCRIPTION =
  'We need to understand your household income, and that includes any salary your spouse earns.';

export const PROVIDE_OTHER_INCOME_TITLE = 'Any other income?';
export const PROVIDE_OTHER_INCOME_MAIN_DESCRIPTION =
  'Other income you may receive could include alimony, unemployment, child support, retirement/pension funds, etc.';
export const PROVIDE_OTHER_INCOME_AGE_DESCRIPTION =
  'This also includes any income for your spouse and other household members over the age of 18.';

export const CONFIRM_INCOME_INFO_TITLE = 'Review your income information';
export const CONFIRM_INCOME_INFO_MAIN_DESCRIPTION =
  'Here is a detailed breakdown and total of all your income:';
export const CONFIRM_INCOME_INFO_DESCRIPTIONP1 =
  'Please review and confirm this information is accurate.';
export const CONFIRM_INCOME_INFO_DESCRIPTIONP2 =
  'I confirm that the income I have entered here is a complete picture of my household income and is accurate.';

export const COMPASS_TOOLTIP =
  'With BuoyFi Calculator, build an affordable plan to pay off medical bills. Tap here to get started.';
export const BILLPAY_TOOLTIP =
  'Make saving for future medical bills easy with Buoyfi Savings and Payments. Tap here to get started.';

export const CONNECT_PAYROLL_PLAID_TITLE =
  'Verify your income through our partner Plaid';
export const CONNECT_PAYROLL_PLAID_MAIN_DESCRIPTION =
  'To see if you qualify for bill reduction, connect your account to share your income details. Then we’ll build payment options based on what you can afford!';
export const CONNECT_PAYROLL_PLAID_MAIN_DESCRIPTION2 =
  'Note: You can only verify your employment once every 30 days.';
export const CONNECT_PAYROLL_PLAID_TOOLTIP =
  'Plaid is a BuoyFi data integration partner used by banks and other financial organizations across the globe to quickly and securely verify income and employment information.';
export const CONNECT_PAYROLL_PLAID_CONFIRM_TEXT =
  'I authorize BuoyFi to access and verify my income information.';
  export const CONNECT_PAYROLL_PLAID_CONFIRM_TEXT_CRA =
  `By checking the box next to this statement, you are providing
    your written instructions to BuoyFi, LLC under the Fair Credit
    Reporting Act, which authorizes BuoyFi to obtain information
    about you from your bank account and other information about
    you from consumer reporting agencies in connection with your
    use of BuoyFi’s calculator which will generate payment
    guidelines to assist in determining how much you can afford to
    pay towards your medical debt.`;

export const INFO_PLAID_TITLE = 'What if I can’t verify my income using Plaid?';
export const INFO_PLAID_MAIN_DESCRIPTION =
  'If for some reason you cannot use Plaid to verify your income, no problem! You can enter your income info manually.';
export const INFO_PLAID_MAIN_DESCRIPTION_P1 =
  'Your bank wasn’t listed on Plaid.';
export const INFO_PLAID_MAIN_DESCRIPTION_P2 =
  'Your payroll system does not connect to Plaid.';
export const INFO_PLAID_MAIN_DESCRIPTION_P3 =
  'You don’t have a W-2 available to upload.';
export const INFO_PLAID_MAIN_DESCRIPTION2 =
  'Possible reasons why your employment info is not on Plaid:';

export const CONNECT_PAYROLL_FAILED_TITLE = 'Verification unsuccessful';
export const CONNECT_PAYROLL_FAILED_MAIN_DESCRIPTION = `We were unable to verify your income at the moment. Please retry or enter your income manually.`;

export const PROVIDE_MONTHLY_INCOME_TITLE = 'Provide your income information';

export const SETUP_BILLPAY_TITLE = 'Save up. Pay down.';

export const SETUP_BILLPAY_AGREEMENTP1 =
  'Buoyfi Savings & Buoyfi Payments pack a 1-2 punch!';
export const SETUP_BILLPAY_AGREEMENTP2 =
  'Effortless Savings and one-stop Payments for any bill help you find and keep your financial footing.';
export const SETUP_BILLPAY_FOOTER_TEXT =
  'BuoyFi is not a bank, it is a financial company and utilizes the Synapse platform to enable certain services. Banking services are provided by Synapse`s bank partners, Members FDIC. By establishing a trust account, you authorize Synapse`s banking partners, Members FDIC, to hold your deposits for your benefit in an account ("Trust Account") by agreeing to the Custodial Account Agreement.';

export const SAVE_SAMPLE_TITLE = 'Savings made simple';
export const SAVE_SAMPLE_DESCRIPTION = `Set up BuoyFi Savings and connect your bank account to build peace of mind with every purchase. We'll round up and stash away your spare change weekly, monthly or manually for no sweat savings on your terms.`;

export const ADDITIONAL_INFO_TITLE = 'Additional Financial Assistance';
export const ADDITIONAL_INFO_DESCRIPTION_CAPIO =
  'If your guidelines are unaffordable, the BuoyFi Concierge team is available to answer your questions.  You may contact the Concierge team by emailing';
export const ADDITIONAL_INFO_DESCRIPTION_MANUAL =
  'If your guidelines are unaffordable or if your account holder is unwilling to accept these guidelines, the BuoyFi Concierge team is available to answer your questions.  You may contact the Concierge team by emailing';

export const MANUAL_LEARN_MORE_DESCRIPTION2 =
  'Use this payment guideline as a tool to negotiate with your agency. You can get a copy of the guideline emailed to your email address below:';

export const DIVINITY_LEARN_MORE_DESCRIPTION2 =
  'Use this payment guideline as a tool to negotiate with your agency. To talk with someone about your balance, please choose from the options below.';

export const SEND_ME_MAIL_TITLE = 'We received your request';
export const SEND_ME_MAIL_DESCRIPTION =
  'Thank you for using BuoyFi Calculator! Your request to be contacted has been sent to the BuoyFi partner associated with this account. Please expect to receive communication in the next 1-2 business days.';
export const SEND_ME_MAIL_GUIDELINE =
  'You should receive your payment guidelines by email within 24 hours.';

export const MANUAL_MAIL_TITLE = 'We look forward to hearing from you';

export const CONNECT_BANK_TOOLTIP =
  'Plaid is a BuoyFi data integration partner used by banks and other financial organizations across the globe to quickly and securely link accounts and safely transfer funds.';
export const CONNECT_BANK_TITLE = 'Link bank account';
export const CONNECT_BANK_DESCRIPTION = `We tally the round ups from this account's daily purchases. Schedule or manually move the total to BuoyFi Savings whenever you like.`;

export const LINK_BILL_PAY_TITLE = 'Link your Bill Pay accounts';
export const LINK_BILL_PAY_DESCRIPTION =
  'Please connect accounts for any bills you want to pay through the app.';

export const BUOYFI_FOOTER_TEXT =
  "Funds are held by Synapse's banking partners, Members FDIC. These funds are held in a custodial account, for your benefit.";

export const REVIEW_TRANSACTION_TITLE = 'Review your transaction';
export const REVIEW_TRANSACTION_DESCRIPTION =
  'By clicking "Confirm", you are authorizing BuoyFi to initiate the following transaction on your behalf:';

export const COMPASS_INFO_TITLE =
  'Below are your guidelines, including some significant potential savings!';

// Household Size
export const ADD_HOUSEHOLD_SIZE_TITLE =
  'How many people are in your household?';
export const ADD_HOUSEHOLD_SIZE_DESCRIPTION =
  'Your household size can determine if you qualify for financial assistance through BuoyFi Assist.';
export const ADD_HOUSEHOLD_SIZE_SELECT = 'Select your household size:';

// Profile
export const FUNDING_ACC_DESCRIPTION =
  'The funding account is the account we deduct your total round up amount from. If you have auto deposit set up, BuoyFi will transfer the calculated round up amount from your funding account to a BuoyFi account to pay off your bills weekly or monthly. You can connect a checking account as your funding account.';
export const SPENDING_ACC_DESCRIPTION =
  'The spending account is the account you use for your daily purchases. We will track your transactions from this account and calculate your round ups. If the account linked is not the one you use to make your daily purchases, you can connect another checking account or debit/credit card.';

export const LOW_INCOME_TITLE = 'You may qualify for free medical services';
export const LOW_INCOME_DESCRIPTION1 =
  'Many healthcare providers have policies that provide for free medical services to those who meet certain eligibility standards and are unable to pay for their cost of care.';
export const LOW_INCOME_DESCRIPTION2 = `Based on the income information you have provided, there is a high likelihood you meet those eligibility standards. If you haven’t already done so, please contact the provider of these services to discuss financial assistance and charity eligibility.`;

export const CONFIRM_INFO_LOADING_TITLE = 'Creating payment guidelines...';
export const CONFIRM_INFO_LOADING_DESCRIPTION = `BuoyFi Calculator uses two decades of bill resolution data from more than 20 million patients to recommend a percentage of your medical bill you can likely afford to pay based on your income.`;

export const EMAIL_GUIDELINE_TITLE = 'Receive Payment guidelines by email';
export const EMAIL_GUIDELINE_DESCRIPTION =
  'You can get a copy of the guideline emailed to your email address below:';

export const GUIDELINES_DISCLAIMER_TEXT = `BuoyFi is not attempting to collect your bill, but has provided
              this contact information for your convenience. BuoyFi assumes no
              liability or responsibility for the communication between you and
              your medical account servicer and does not guarantee that your
              medical account servicer will honor BuoyFi’s payment guidelines.
              Monthly payment amount for your last month may vary or be less than 
              what is listed. Please check in with your account servicer for more details.`;


export const SUPPORT_EMAIL = 'customerservice@buoyfi.com';
export const SUPPORT_PHONE = '1(888) 308-6004';
export const BANK_SERVICE_EMAIL = 'help@Synapsefi.com';
export const CUSTOMER_SERVICE_EMAIL = 'customerservice@buoyfi.com';

export const HELP_LINE_1 =
  'For assistance with any BuoyFi related questions or issues, please contact us';
export const HELP_LINE_2 =
  'Our support hours are Monday - Friday, 8am - 4pm CST.';
export const TERMS_URL =
  'https://buoy-pdf.s3.us-east-2.amazonaws.com/20230519_BuoyFiUserAgreement_CMA.pdf';
export const PRIVACY_URL =
  'https://buoy-pdf.s3.us-east-2.amazonaws.com/BuoyFi+Privacy+Policy.pdf';
export const AGREEMENT_URL =
  'https://buoy-pdf.s3.us-east-2.amazonaws.com/Buoyfi+Custody+Agreements.pdf';

export const COPY_RIGHT = '© 2025 BuoyFi, LLC. All rights reserved.';

// Progress indicator steps
export const PROGRESS_INDICATOR_STEPS = {
  Step1: 'Share Bill Information',
  Step1Capio: 'Review Bill Information',
  Step2Manual: 'Enter Income Manually',
  Step2Plaid: 'Confirm Income with Plaid',
  Step2AddInfo: 'Share Additional Info',
  Step2AddIncome: 'Share Additional Income',
  Step3: 'Review Income Information',
};

export const WIDGET_TYPE = {
  ADD_PAYEE: 'add-payee',
  MY_PAYEES: 'my-payees',
  LINK_PAYEE: 'link-payee',
  PAYEE_DETAIL: 'payee-details',
  PAYMENT_HISTORY: 'payment-history',
  // FIXED_RECURRING: 'fixed-recurring',
  SINGLE_PAYMENT: 'single-payment',
  BILLS_DUE: 'bills-due',
  SCHEDULED_PAYMENTS: 'scheduled-payments',
  VIEW_PAYMENT: 'view-payment',
  PAYMENT: 'payment',
  LINK_PAYEE: 'link-payee',
};

export const BILLGO_WIDGET = {
  [WIDGET_TYPE.ADD_PAYEE]: {
    widgetName: 'AddPayee',
    eventName: 'ADD_PAYEE',
  },
  [WIDGET_TYPE.MY_PAYEES]: {
    widgetName: 'MyPayees',
    eventName: 'MY_PAYEES',
  },
  [WIDGET_TYPE.LINK_PAYEE]: {
    widgetName: 'LinkPayee',
    eventName: 'LINK_PAYEE',
  },
  [WIDGET_TYPE.PAYEE_DETAIL]: {
    widgetName: 'PayeeDetail',
    eventName: 'PAYEE_DETAIL',
  },
  [WIDGET_TYPE.VIEW_PAYMENT]: {
    widgetName: 'ViewPayment',
    eventName: 'PAYMENT',
  },
  [WIDGET_TYPE.PAYMENT]: {
    widgetName: 'Payment',
    eventName: 'PAYMENT',
  },
  [WIDGET_TYPE.LINK_PAYEE]: {
    widgetName: 'LinkPayee',
    eventName: 'LINK_PAYEE',
  },
  // [WIDGET_TYPE.FIXED_RECURRING]: {
  //   widgetName: 'FixedRecurring',
  //   eventName: 'FIXED_RECURRING',
  // },
  [WIDGET_TYPE.SINGLE_PAYMENT]: {
    widgetName: 'SinglePayment',
    eventName: 'PAYMENT',
  },
  [WIDGET_TYPE.BILLS_DUE]: {
    widgetName: 'BillsDue',
    eventName: 'BILLS_DUE',
  },
  [WIDGET_TYPE.PAYMENT_HISTORY]: {
    widgetName: 'PaymentHistory',
    eventName: 'PAYMENT_HISTORY',
  },
  [WIDGET_TYPE.SCHEDULED_PAYMENTS]: {
    widgetName: 'ScheduledPayments',
    eventName: 'SCHEDULED_PAYMENTS',
  },
};

export const WIDET_TABS = [
  {
    label: 'Bills',
    value: WIDGET_TYPE.BILLS_DUE,
  },
  {
    label: 'Accounts',
    value: WIDGET_TYPE.MY_PAYEES,
  },
  // {
  //   label: 'Fixed Recurring',
  //   value: WIDGET_TYPE.FIXED_RECURRING,
  // },
  {
    label: 'Payment History',
    value: WIDGET_TYPE.PAYMENT_HISTORY,
  },
  {
    label: 'Scheduled Payments',
    value: WIDGET_TYPE.SCHEDULED_PAYMENTS,
  },
];

export const BILLGO_URL = 'https://app2.buoyfi.com';

export const NOTIFICATION_TYPES = Object.freeze({
  ATTENTION: 'Attention',
  SCHEDULE: 'Schedule',
  TIPS: 'Tips',
  SUCCESS: 'Success',
  PAYMENT: 'Payment',
  PLAID: 'Plaid',
});

export const NOTIFICATION_STATUS = {
  PROCESSED: 'Processed',
  UNPROCESSED: 'Unprocessed',
};
